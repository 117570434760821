import React, {memo} from 'react';
import {graphql} from 'gatsby';

import {Trans} from 'gatsby-plugin-react-i18next';
import PageWrapper from '../../containers/PageWrapper';
import ContactForm from '../../modules/ContactForm';
import ImageSection from '../../components/ImageSection';
import Section from '../../components/Section';
import UnderlineHeading from '../../components/UnderlineHeading';
import SectionDescription from '../../components/SectionDescription';
import HorizontalCards from '../../components/HorizontalCards';
import SmallCards from '../../components/SmallCards';

const IntegrationPlatforms = ({data}) => (
  <PageWrapper
    title="Integration Platforms"
    description="Having a multiple distributed and loosely coupled systems has its pros and cons. Sometimes organization requires a single platform that integrates various systems. CoderyLabs utilizes Liferay for that purpose - thanks to its modular architecture and high customization and extendability it’s a perfect solution."
    urlPath="solutions/integration-platforms"
    keywords={['coderylabs', 'integration platforms']}
  >
    <ImageSection fluid={data.mainPhoto.childImageSharp.fluid}/>

    <Section>
        <UnderlineHeading><Trans>Integration platforms</Trans></UnderlineHeading>
      <SectionDescription>
          <Trans>Integration Platforms desc</Trans>
      </SectionDescription>
    </Section>

    <Section theme="dark">
        <UnderlineHeading><Trans>Features</Trans></UnderlineHeading>
      <SectionDescription style={{marginBottom: '2rem'}}>
          <Trans>Feature int platforms desc</Trans>
      </SectionDescription>

      <SmallCards
        items={[
          {
            fluid: data.readyToUseConnectors.childImageSharp.fluid,
              heading: <Trans>Ready To Use Connectors</Trans>,
              description: <Trans>Ready To Use Connectors des</Trans>,
          },
          {
            fluid: data.multipleDataProviders.childImageSharp.fluid,
              heading: <Trans>Multiple Data Providers</Trans>,
              description: <Trans>Multiple Data Providers desc</Trans>,
          },
        ]}
      />
      <SmallCards
        items={[
          {
            fluid: data.singleSignOn.childImageSharp.fluid,
            heading: <Trans>Single-Sign-On</Trans>,
            description: <Trans>Single-Sign-On desc</Trans>,
          },
          {
            fluid: data.userIdentityManagement.childImageSharp.fluid,
              heading: <Trans>User Identity Management</Trans>,
              description: <Trans>User Identity Management desc</Trans>,
          },
        ]}
      />
      <SmallCards
        items={[
          {
            fluid: data.highExtendability.childImageSharp.fluid,
              heading: <Trans>High Extendability</Trans>,
            description: <Trans>High Extendability desc</Trans>,
          },
          {
            fluid: data.buildInApis.childImageSharp.fluid,
              heading: <Trans>Build-In Apis</Trans>,
              description: <Trans>Build-In Apis desc</Trans>,
          },
        ]}
      />
    </Section>

    <Section>
      <UnderlineHeading><Trans>Benefits</Trans></UnderlineHeading>
      <SectionDescription><Trans>Benefits desc int platf desc</Trans></SectionDescription>

      <HorizontalCards
        theme="dark"
        items={[
          {
            fluid: data.easyAcquistionsAndMerges.childImageSharp.fluid,
              heading: <Trans>Easy Acquistions And Merges</Trans>,
            description: <Trans>Easy Acquistions And Merges desc</Trans>,
          },
          {
            fluid: data.convenientForUsers.childImageSharp.fluid,
              heading: <Trans>Convenient For Users</Trans>,
              description: <Trans>Convenient For Users desc</Trans>,
          },
          {
            fluid: data.costSavings.childImageSharp.fluid,
              heading: <Trans>Cost Savings</Trans>,
              description: <Trans>Cost Savings desc</Trans>,
          },
          {
            fluid: data.flexibility.childImageSharp.fluid,
              heading: <Trans>Flexibility</Trans>,
              description: <Trans>Flexibility int platforms desc</Trans>,
          },
        ]}
      />
    </Section>

    <Section theme="dark">
      <UnderlineHeading><Trans>Contact Us</Trans></UnderlineHeading>
      <SectionDescription>
        <Trans>Contact Us Hint</Trans>
      </SectionDescription>
      <ContactForm/>
    </Section>
  </PageWrapper>
);

export default memo(IntegrationPlatforms);

export const query = graphql`
  query {
    mainPhoto: file(relativePath: { eq: "solutions/integrationPlatforms/main_photo.png" }) {
      childImageSharp {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    easyAcquistionsAndMerges: file(relativePath: { eq: "solutions/integrationPlatforms/cards/easy_acquistions_and_merges.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    convenientForUsers: file(relativePath: { eq: "solutions/integrationPlatforms/cards/convenient_for_users.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    costSavings: file(relativePath: { eq: "solutions/integrationPlatforms/cards/cost_savings.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    flexibility: file(relativePath: { eq: "solutions/integrationPlatforms/cards/flexibility.png" }) {
      childImageSharp {
        fluid(maxWidth: 150, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    readyToUseConnectors: file(relativePath: { eq: "solutions/integrationPlatforms/cards/ready_to_use_connectors.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    multipleDataProviders: file(relativePath: { eq: "solutions/integrationPlatforms/cards/multiple_data_providers.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    singleSignOn: file(relativePath: { eq: "solutions/integrationPlatforms/cards/single-sign-on.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    userIdentityManagement: file(relativePath: { eq: "solutions/integrationPlatforms/cards/user&identity_management.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    highExtendability: file(relativePath: { eq: "solutions/integrationPlatforms/cards/high_extendability.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    buildInApis: file(relativePath: { eq: "solutions/integrationPlatforms/cards/build-in_apis.png" }) {
      childImageSharp {
        fluid(maxWidth: 60, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
